<template>
  <div id="preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ pageDescription }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-container class="py-0" v-if="StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container class="py-0" v-if="ResultFlag">
          <v-row wrap
            ><v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Paid Date</h5>
                <p class="preview-content">
                  {{ recordData.PmOrderDate }} <br />
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Event Name</h5>
                <p class="preview-content">
                  {{ recordData.ProgramName }} <br />
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Venue Details</h5>
                <p class="preview-content">
                  {{ recordData.VenueDetails }} <br />
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Event Date</h5>
                <p class="preview-content">
                  {{ recordData.ProgramDateTxt }} <br />
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Host Name</h5>
                <p class="preview-content">{{ recordData.HostName }} <br /></p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Member Name</h5>
                <p class="preview-content">
                  {{ recordData.MemberNameTxt }} <br />
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Member Photo</h5>
                <v-img :src="recordData.MemberPhoto" height="100" width="100">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">LO Name</h5>
                <p class="preview-content">{{ recordData.LomName }} <br /></p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Zone Name</h5>
                <p class="preview-content">{{ recordData.ZoneName }} <br /></p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Mobile Number</h5>
                <p class="preview-content">{{ recordData.MobileNo }} <br /></p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Email Address</h5>
                <p class="preview-content">{{ recordData.EmailId }} <br /></p>
              </v-col>
            </v-row>
          </v-row>

          <v-row wrap v-if="recordData.ReasonHistory != ''">
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="blue--text text--lighten">Approval Remarks</h5>
              <p
                class="font-size-h6 mt-3"
                v-html="recordData.ReasonHistory"
              ></p>
            </v-col>
          </v-row>
        </v-container>

        <br />
        <hr />

        <v-container v-if="recordData.ApprovalPermissionFlag">
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12" align="center">
              <h4>For administrative purpose only</h4>
            </v-col>
          </v-row>
          <v-form
            ref="form2"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-row wrap>
              <v-col cols="12" sm="12" lg="6" md="6">
                <label>
                  <h6><span class="text-danger">*</span>Remarks</h6>
                </label>
                <v-text-field
                  v-model="Description"
                  :rules="DescriptionRules"
                  hint="Enter the remarks"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm(1)"
                  :loading="ApproveLoadingFlag"
                  color="success"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Approve
                </v-btn>
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm(2)"
                  :loading="RejectLoadingFlag"
                  color="warning"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Reject
                </v-btn>
                <v-btn
                  @click="closePrompt"
                  color="primary"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    row: {
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      recordData: [],
      ProgramDocuments: [],
      ResultFlag: false,
      StartupLoadingFlag: false,

      ReportType: "",

      Description: "",
      DescriptionRules: [(v) => !!v || "Remarks is required"],

      valid1: true,
      StatusFlag: false,
      ApproveLoadingFlag: false,
      RejectLoadingFlag: false,
    };
  },
  computed: {},
  watch: {
    recordData: function () {
      console.log("watch recordData");
      this.StartupLoadingFlag = false;
      var tr = this.recordData;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      this.ResultFlag = n1 > 0 ? true : false;
      this.ReportType = n1 > 0 ? tr.CategoryName : "";
      this.ProgramDocuments = n1 > 0 ? tr.ProgramDocuments : [];
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.StatusFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getRecordDetails();
    },
    getRecordDetails() {
      console.log("getRecordDetails called");
      this.ResultFlag = false;
      this.StartupLoadingFlag = true;

      var tr = this.row;
      console.log({ tr });

      var list_url = "api/program-registration/show";
      var where = {
        UserInterface: 2,
        ProgramRegistration: tr.EventRegistrationId,
        Member: tr.MemberId,
      };
      console.log("list_url=" + list_url + ", where=" + JSON.stringify(where));
      this.getTableData("recordData", list_url, where);
    },
    submitForm(Status) {
      console.log("submitForm is called");

      console.log({ Status });

      var message = "";

      var validate1 = this.$refs.form2.validate();
      console.log({ validate1 });

      var tr = this.row;
      console.log({ tr });

      var id = tr.EventRegistrationId;
      console.log({ id });

      // var validate1 = false;

      if (validate1 && Status > 0 && id > 0) {
        switch (Status) {
          case 1:
            this.ApproveLoadingFlag = true;
            break;

          case 2:
            this.RejectLoadingFlag = true;
            break;

          default:
            break;
        }

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/jci-program/registration/update-approval-status";
        var upload = {
          UserInterface: Status,
          ProgramRegistration: id,
          Member: tr.MemberId,
          Remarks: this.Description,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.StatusFlag = false;

        const thisIns = this;
        var output = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            switch (Status) {
              case 1:
                thisIns.ApproveLoadingFlag = false;
                break;

              case 2:
                thisIns.RejectLoadingFlag = false;
                break;

              default:
                break;
            }

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.StatusFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        if (!validate1) {
          message = "Kindly fill the required fields. ";
        }
        if (Status == null || Status == "") {
          message = "Status is invalid. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
